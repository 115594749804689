.buttonsContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  margin-bottom: 20px;
}

.button {
  display: flex;
  align-items: center;
  height: 38px;
  padding: 0px 16px;
  background-color: #EEE;
  color: #666;
  border: 0;
  gap: 8px;
  border-radius: 18px;
  font-size: 15px;
  user-select: none;
}

.icon {
  width: 22px;
  height: 22px;
}

.sectionTitle {
  font-size: 14px;
  font-weight: 700;
  color: #3B3B3B;
}

.list {
  max-height: 50vh;
  overflow: auto;
  margin-bottom: 22px;
}

.playersContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 21px 0px;
}

.player {
  text-align: center;
  padding: 0px 20px;
}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 27px;
  border: 2px solid white;
}

.avatarSelected {
  width: 50px;
  height: 50px;
  border-radius: 27px;
  border: 2px solid #00A826;
}

.name {
  font-size: 13px;
  color: #3B3B3B;
  text-align: center;
  padding-bottom: 16px;
  width: 60px;
  margin: auto;
}

.submit {
  height: 38px;
  width: 100%;
  border-radius: 4px;
  border: 0;
  background-color: #00A826;
  align-content: center;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  color: white;
}