.container {
  background-color: #000;
  display: flex;
  gap: 8px;
  padding: 15px 10px;
  overflow-x: scroll;
}

.tab {
  background-color: #1E1E1E;
  color: #999;
  border-radius: 16px;
  padding: 8px 20px;
  font-size: 14px;
  user-select: none;
}

.tabSelected {
  background-color: #00A826;
  color: #fff;
}