.container {
  background-color: #F2F2F2;
  overflow-y: scroll;
  height: calc(100vh - 60px);
}

.prizesContainer {
  background-color: #fff;
  border-radius: 10px;
  margin: 15px;
  padding: 15px;
}