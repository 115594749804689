.container {
  position: relative;
  text-align: center;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 10px;
  align-items: center;
  padding: 20px 15px 15px 15px;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 24px;
  height: 24px;
  align-items: center;
}

.title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 15px;
}

.info {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 15px;
}

.infoImage {
  height: 130px;
}

.rulesButton {
  height: 40px;
  width: 100%;
  border-radius: 4px;
  border: 0;
  background-color: #99999954;
  align-content: center;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  color: #333333;
  margin-top: 20px;
}

.rulesButton:first-child {
  margin-right: 5px;
}

.rulesButton:last-child {
  margin-left: 5px;
}

.buttonsWrapper {
  display: flex;
  justify-content: space-between;
}
