.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.teamsContainer {
  display: flex;
  flex-direction: row;
}

.logoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 80px;
  padding: 10px 10px 25px 10px;
}

.logo {
  width: 60px;
  height: 60px;
  border-radius: 33px;
  border: 3px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F2F2F2;
  margin-bottom: 5px;
  position: relative;
}

.selectedLogo {
  border: 3px solid #00A826;
}

.checkmark {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 0px;
  right: 0px;
}

.icon {
  width: 35px;
  height: 35px;
}

.submit {
  height: 38px;
  width: 100%;
  border-radius: 4px;
  border: 0;
  background-color: #00A826;
  align-content: center;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  color: white;
}