.container {
  margin-top: 20px;
}

.list {
  background-color: #fff;
  border-radius: 10px;
  padding-bottom: 5px;
}

.listHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 700;
  padding: 10px 15px;
}

.separator {
  width: 100%;
  height: 1px;
  background-color: #F2F2F2;
}

.row {
  min-height: 38px;
  margin-top: 15px;
  padding: 0px 15px;
}

.rowTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2px;
}

.rowName {
  font-size: 14px;
  padding-right: 20px;
}

.rowRank {
  font-size: 14px;
  font-weight: 700;
}

.rowInfo {
  font-size: 12px;
  color: #999;
}