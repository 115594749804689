.container {
  display: flex;
  flex-direction: column;
}

.cardsContainer {
  background-color: #F2F2F2; 
  padding: 15px;
  overflow-y: scroll;
  height: calc(100vh - 85px);
}