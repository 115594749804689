.calendar {
  display: flex;
  flex-wrap: nowrap;
  height: 46px;
  background-color: #2C2C2C;
  color: #fff;
  overflow-x: scroll;
}

.dateContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px 12px;
  user-select: none;
}

.dateContainerSelected {
  border-bottom: 3px solid #00A826;
}

.date {
  line-height: 18px;
  font-size: 12px;
  color: #999999;
}

.dateSelected {
  color: #fff;
  font-weight: 700;
}