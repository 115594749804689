.container {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  border-radius: 8px;
  left: 50%;
  transform: translateX(-50%);
  bottom: 20px;
  color: #fff;
  align-content: center;
  text-align: center;
  font-size: 13px;
  font-weight: 700;
  background-color: #00A826;
  box-shadow: 0px 4px 12px 0px #00000033;
}

.error {
  background-color: #ED1C24;
}

.icon {
  margin-right: 5px;
  width: 16px;
  height: 16px;
}