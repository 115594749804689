.container {
  display: flex;
  flex-direction: column;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  margin-bottom: 20px;
}

.button {
  display: flex;
  align-items: center;
  height: 38px;
  padding: 0px 16px;
  background-color: #EEE;
  color: #666;
  border: 0;
  gap: 8px;
  border-radius: 18px;
  font-size: 15px;
  user-select: none;
}

.icon {
  width: 22px;
  height: 22px;
  /* border-radius: 11px; */
}

.list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.resultIcon {
  display: flex;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  color: #FFF;
  text-align: center;
  font-size: 11px;
  font-weight: 500;
  margin-right: 12px;
}

.label {
  color: #000;
  font-size: 14px;
}

.date {
  color: #999;
  font-size: 12px;
}

.row {
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
}