.container {
  background-color: #fff;
  border-radius: 10px;
  padding: 15px;
}

.title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 7px;
}

.subtitle {
  font-size: 14px;
}

.createButton {
  margin-top: 18px;
  margin-bottom: 10px;
  height: 38px;
  width: 100%;
  border-radius: 4px;
  border: 0;
  background-color: #00A826;
  align-content: center;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  color: white;
}

.joinButton {
  height: 38px;
  width: 100%;
  border-radius: 4px;
  border: 0;
  background-color: #99999954;
  align-content: center;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  color: #333333;
}