.submitButton {
  margin-top: 10px;
  margin-bottom: 10px;
  height: 38px;
  width: 100%;
  border-radius: 4px;
  border: 0;
  background-color: #ED1C24;
  align-content: center;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  color: white;
}

.greenButton {
  background-color: #00A826;
}

.cancelButton {
  height: 38px;
  width: 100%;
  border-radius: 4px;
  border: 0;
  background-color: #99999954;
  align-content: center;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  color: #333333;
}