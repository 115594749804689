.background {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.container {
  background: white;
  width: 100%;
  padding: 30px 20px 20px 20px;
  margin-left: 25px;
  margin-right: 25px;
  border-radius: 16px;
  position: relative;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 15px;
  width: 24px;
  height: 24px;
  align-items: center;
  color: black;
}

.title {
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 5px;
}

.subtitle {
  text-align: center;
  font-size: 14px;
  color: #666666;
  margin-bottom: 20px;
}

.points {
  font-weight: 700;
}