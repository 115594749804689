.container {
    display: flex;
    flex-direction: column;
}

.cardsContainer {
    background-color: #F2F2F2; 
    padding: 15px;
    overflow-y: scroll;
    height: calc(100vh - 185px);
}

.cardsContainerSafari {
    background-color: #F2F2F2; 
    padding: 15px;
    overflow-y: scroll;
    height: calc(100vh - 130px);
}

.shareButton {
    margin-top: 8px;
    margin-bottom: 20px;
    height: 38px;
    width: 100%;
    border-radius: 4px;
    border: 0;
    background-color: #00A826;
    align-content: center;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    color: white;
  }

