.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.switchButton {
  display: flex;
  align-items: center;
  width: 122px;
  height: 45px;
  background-color: #eee;
  border: 0px;
  border-radius: 12px;
  margin-bottom: 25px;
}

.text {
  display: flex;
  height: 33px;
  width: 55px;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border-radius: 12px;
  color: #999;
  font-size: 14px;
}

.textToggled {
  color: #00A826;
  font-weight: 700;
  background-color: white;
}

.submit {
  height: 38px;
  width: 100%;
  border-radius: 4px;
  border: 0;
  background-color: #00A826;
  align-content: center;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  color: white;
}