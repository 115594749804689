.input {
  width: calc(100% - 32px);
  border: 1px solid #E5E5E5;
  border-radius: 8px;
  background-color: #fff;
  color: #000;
  font-size: 14px;
  padding: 15px;
  margin-top: 16px;
  margin-bottom: 24px;
}

.button {
  height: 38px;
  width: 100%;
  border-radius: 4px;
  border: 0;
  background-color: #00A826;
  align-content: center;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  color: white;
}

.buttonDisabled {
  background-color: #ccc;
}