.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
}

.predictionContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.pickerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.logo {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F2F2F2;
  margin-bottom: 5px;
}

.icon {
  width: 35px;
  height: 35px;
}

.name {
  color: #3B3B3B;
  font-size: 14px;
}

.buttonContainer {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  width: 14px;
  height: 14px;
}

.input {
  width: 34px;
  height: 34px;
  border: 1px solid #E5E5E5;
  border-radius: 8px;
  background-color: #fff;
  color: #000;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
}

.dash {
  color: #CCCCCC;
  align-self: center;
  font-size: 24px; 
  font-weight: 700;
  margin: 10px;
}

.result {
  text-align: center;
}

