.container {
  display: flex;
  flex-direction: column;
  background-color: #f2f2f2;
  overflow-y: scroll;
  height: calc(100vh - 60px);
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 15px 15px 15px;
  background-color: #fff;
}

.rowBack {
  width: 24px;
  height: 24px;
  margin-right: 12px;
}

.rowTitle {
  display: flex;
  flex-direction: column;
}

.rowName {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 2px;
}

.rowInfo {
  font-size: 14px;
  color: #999;
}

.moreIcon {
  width: 32px;
  height: 32px;
  margin-left: auto;
}

.leaveIcon {
  width: 24px;
  height: 24px;
  margin-left: auto;
}

.codeInfoContainer {
  background-color: #fff;
  margin: 15px;
  padding: 15px;
  border-radius: 8px;
}

.codeTitle {
  font-size: 14px;
  color: #666;
  margin-bottom: 6px;
}

.codeContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px dashed #ccc;
  border-radius: 8px;
}

.code {
  font-size: 16px;
  font-weight: 700;
  padding: 10px 20px;
}

.codeIcon {
  width: 15px;
  height: 15px;
  margin-right: 15px;
}

.messageRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
  padding: 0px 15px;
  min-height: 50px;
  margin: 0px 15px 15px 15px;
  background-color: #e4edf5;
}

.messageIcon {
  width: 20px;
  height: 20px;
  padding-right: 10px;
}

.messageText {
  font-size: 14px;
  font-weight: 400;
  color: #000;
}

.userRanking {
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #00a826;
  margin: 0px 15px 10px 15px;
  padding: 0px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
}

.membersList {
  background-color: #fff;
  border-radius: 10px;
  margin: 0px 15px 15px 15px;
  padding-bottom: 10px;
}

.membersListHeader {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 700;
  padding: 10px 15px;
}

.memberPointsHeader {
  font-size: 14px;
  font-weight: 700;
  margin-left: auto;
}

.separator {
  width: 100%;
  height: 1px;
  background-color: #f2f2f2;
}

.memberRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 15px;
}

.avatarIcon {
  width: 32px;
  height: 32px;
  border-radius: 16px;
  margin: 6px 12px 6px 0px;
}

.memberName {
  font-size: 14px;
}

.memberPoints {
  font-size: 14px;
  margin-left: auto;
}

.memberRank {
  width: 40px;
  font-size: 14px;
  font-weight: 700;
  margin-left: 25px;
  text-align: right;
}

.shareCode {
  margin-top: 8px;
  height: 38px;
  width: 100%;
  border-radius: 4px;
  border: 0;
  background-color: #00a826;
  align-content: center;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  color: white;
}
