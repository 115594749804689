.container {
  display: flex;
  flex-direction: column;
  background-color: #f2f2f2;
  overflow-y: scroll;
  height: calc(100vh - 60px);
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 15px 15px 15px;
  background-color: #fff;
}

.headerIcon {
  width: 24px;
  height: 24px;
  margin-right: 15px;
}

.rowTitle {
  display: flex;
  flex-direction: column;
}

.rowName {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 2px;
}

.rowInfo {
  font-size: 14px;
  color: #999;
}

.messageRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
  min-height: 50px;
  padding: 0px 10px;
  margin: 10px 15px 0px 15px;
  background-color: #e4edf5;
}

.messageIcon {
  width: 20px;
  height: 20px;
  padding-right: 10px;
}

.messageText {
  font-size: 14px;
  font-weight: 400;
  color: #000;
}

.userRanking {
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #00a826;
  margin: 10px 15px 0px 15px;
  padding: 0px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
}

.list {
  background-color: #fff;
  border-radius: 10px;
  margin: 15px;
  padding-bottom: 10px;
}

.listHeader {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 700;
  padding: 10px 15px;
}

.playersPointsHeader {
  font-size: 14px;
  font-weight: 700;
  margin-left: auto;
}

.separator {
  width: 100%;
  height: 1px;
  background-color: #f2f2f2;
}

.playerRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 15px;
}

.avatarIcon {
  width: 32px;
  height: 32px;
  border-radius: 16px;
  margin: 6px 12px 6px 0px;
}

.playerName {
  font-size: 14px;
}

.playerPoints {
  font-size: 14px;
  margin-left: auto;
}

.playerRank {
  width: 40px;
  font-size: 14px;
  font-weight: 700;
  margin-left: 25px;
  text-align: right;
}

.emptyListContainer {
  background-color: #fff;
  border-radius: 10px;
  margin: 15px;
  text-align: center;
}

.emptyListImage {
  width: 45%;
  margin-top: 70px;
  margin-bottom: 20px;
}

.emptyListTitle {
  font-size: 16px;
  font-weight: 700;
  color: #3b3b3b;
  margin-bottom: 12px;
}

.emptyListDescription {
  font-size: 14px;
  font-weight: 400;
  color: #666;
  margin: 12px 40px 70px 40px;
}

.selectLabel {
  margin: 0 0 10px 0;
  font-weight: bold;
  font-size: 14px;
  text-align: left;
}

.selectElement {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  appearance: none;
  position: relative;
}
