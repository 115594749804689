.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 32px;
  gap: 10px;
  margin: 0px 0px 15px 0px;
}

.scoreButton {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  width: 25%;
  height: 32px;
  background-color: #F6F6F6;
  border: 0;
  border-radius: 6px;
  user-select: none;
}

.selectedButton {
  background-color: #00A826;
}

.score {
  color: #000;
  font-size: 13px;
  font-weight: 700;
}

.percentage {
  color: #00861E;
  font-size: 13px;
  font-weight: 700;
}

.selectedText {
  color: #fff;
}