.container {
  display: flex;
  justify-content: center;
  padding: 11px 0px 16px 0px;
}

.button {
  display: flex;
  align-items: center;
  user-select: none;
}

.text {
  color: #3B3B3B;
  font-size: 13px;
  text-decoration-line: underline;
  cursor: pointer;
}

.icon {
  width: 8px;
  height: 8px;
  margin-left: 5px;
}

.points {
  width: 55px;
  height: 25px;
  background-color: #FFC421;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  margin-left: 20px;
  margin-bottom: -21px;
  font-size: 12px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rowsContainer {
  padding: 0px 20px 12px 20px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  user-select: none;
  background-color: #F6F6F6;
  height: 38px;
  border-radius: 8px;
  margin-bottom: 8px;
  padding: 0px 10px 0px 10px;
}

.rowApplied {
  background-color: #00A82633;
}

.rowDisabled {
  opacity: 0.5;
}

.rowText {
  color: #000;
  font-size: 13px;
}

.iconContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.rowIcon {
  width: 22px;
  height: 22px;
  border-radius: 11px;
}

.statusIcon {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  margin-left: -4px;
}

.resultText {
  display: flex;
  align-items: center;
  background-color: #fff;
  height: 22px;
  border-radius: 10px;
  padding: 0px 5px;
  font-size: 10px;
  font-weight: 700;
}