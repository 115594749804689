.container {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px 10px 15px;
  font-size: 12px;
  user-select: none;
}

.date {
  color: #999;
}

.live {
  color: #fff;
  background-color: #00A826;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 700;
  width: 40px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.statisticsContainer {
  display: flex;
  align-items: center;
}

.statisticsText {
  color: #3B3B3B;
  font-size: 12px;
  text-decoration-line: underline;
  cursor: pointer;
}

.statisticsIcon {
  width: 15px;
  height: 15px;
  margin-left: 5px;
}