.container {
  background-color: #F2F2F2;
  text-align: center;
  overflow-y: scroll;
  height: calc(100vh - 60px);
}

.rulesContainer {
  background-color: #fff;
  border-radius: 10px;
  margin: 15px;
  padding: 15px;
}

.rulesTitle {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  padding-bottom: 25px;
}

.rulesIcon {
  height: 150px;
}

.separator {
  margin: 20px;
  height: 1px;
  background-color: #DDD;
}

.rulesContent {
  margin-top: 20px;
  text-align: left;
}