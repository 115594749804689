.container {
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 10px;
}

.separatorTop {
  width: 100%;
  height: 1px;
  background-color: #F2F2F2;
}

.separatorBottom {
  width: 100%;
  height: 1px;
  background-color: #F2F2F2;
}