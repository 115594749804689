.background {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  z-index: 2;
}

.container {
  background: white;
  width: 100%;
  padding: 25px 0px 20px 0px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  position: relative;
}

.closeButton {
  position: absolute;
  top: 25px;
  right: 15px;
  width: 24px;
  height: 24px;
  align-items: center;
  color: black;
}

.title {
  color: #333;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 5px;
  padding: 0px 35px 0px 15px;
}

.optionButton {
  display: flex;
  flex-direction: row;
  height: 50px;
  align-items: center;
}

.optionIcon {
  width: 15px;
  height: 15px;
  margin: 0px 10px 0px 20px;
}

.optionTitle {
  font-size: 14px;
  color: #333;
}

.separator {
  height: 1px;
  margin: 0px 15px 0px 15px;
  background-color: #eee;
}