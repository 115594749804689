.container {
  display: flex;
  flex-direction: row;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px 15px 10px 15px;
  margin-bottom: 10px;
}

.icon {
  width: 60px;
  height: 60px;
  margin-right: 15px;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title {
  color: #3b3b3b;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 4px;
}

.description {
  color: #3b3b3b;
  font-size: 14px;
  font-weight: 400;
}