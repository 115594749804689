.container {
  margin-top: 20px;
}

.header {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 15px;
}

.list {
  background-color: #fff;
  border-radius: 10px;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 57px;
  padding: 0px 15px;
}

.rowIcon {
  width: 24px;
  height: 24px;
  margin-right: 12px;
}

.rowTitle {
  display: flex;
  flex-direction: column;
}

.rowName {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 2px;
  padding-right: 20px;
}

.rowInfo {
  font-size: 12px;
  color: #999;
}

.rowChevron {
  width: 24px;
  height: 24px;
  margin-left: auto;
}