.input {
  width: calc(100% - 32px);
  border: 1px solid #E5E5E5;
  border-radius: 8px;
  background-color: #fff;
  color: #000;
  font-size: 14px;
  padding: 15px;
  margin-top: 16px;
  margin-bottom: 24px;
}

.title {
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 15px;
}

.list {
  max-height: 170px;
  overflow-x: scroll;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 6px 0px 6px 0px;
}

.user {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.avatar {
  width: 32px;
  height: 32px;
  margin-right: 12px;
}

.name {
  font-size: 14px;
  font-weight: 400;
}

.removeButton {
  font-size: 14px;
  font-weight: 400;
  color: #00861E;
}

.submitButton {
  margin-top: 18px;
  margin-bottom: 10px;
  height: 38px;
  width: 100%;
  border-radius: 4px;
  border: 0;
  background-color: #00A826;
  align-content: center;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  color: white;
}

.disabledButton {
  margin-top: 18px;
  margin-bottom: 10px;
  height: 38px;
  width: 100%;
  border-radius: 4px;
  border: 0;
  background-color: #CCC;
  align-content: center;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  color: white;
}